<template>
<div class="nav-container">
    <nav class>
        <div class="nav-wrapper container">
            <!-- <router-link to="/" class="brand-logo">HoFH Recipes</router-link> -->
            <ul id="nav-mobile" class="left">
                <router-link to="/" class="brand-logo">
                    <i class="material-icons">home</i>
                </router-link>
                <li v-if="user">
                    <a href="#" data-target="slide-out" class="sidenav-trigger">
                        <i class="material-icons">account_circle</i>
                    </a>
                </li>
                <li v-if="user">
                    <router-link to="/favourites">Favorite recipes</router-link>
                </li>
                <li>
                    <a @click="logInWarning('mealPlan')">Meal planner</a>
                </li>
                <li>
                    <a @click="logInWarning('profile')">Health tracker</a>
                </li>
                <a class="dropdown-trigger" href="#" data-target="dropdownLifestyle">Lifestyle tips</a>
                <li v-if="!user">
                    <router-link to="/signup">Signup</router-link>
                </li>
                <li v-if="!user">
                    <router-link to="/login">Login</router-link>
                </li>
                <!-- <li v-if="user"><a>{{user.email}}</a></li> -->
                <li v-if="user">
                    <a @click="logout">Logout</a>
                </li>
            </ul>
            <ul class="right">
                <li><a class="dropdown-trigger dropdownLangBtn" href="#!" data-target="dropdownLang">Language<i class="material-icons right">arrow_drop_down</i></a></li>
            </ul>
        </div>
    </nav>

    <!-- Lifestyle dropdown -->
    <ul id="dropdownLifestyle" class="dropdown-content">
        <li>
            <router-link :to="'/lifestyle-tips/MaintainingWeight'">Maintaining weight</router-link>
        </li>
        <li>
            <router-link :to="'/lifestyle-tips/EatingOnABudget'">Eating on a Budget</router-link>
        </li>
        <li>
            <router-link :to="'/lifestyle-tips/SmartFoodSubstitutions'">Smart food substitutions</router-link>
        </li>
        <li>
            <router-link :to="'/lifestyle-tips/CarbControl'">Carb control</router-link>
        </li>
    </ul>

    <!-- Language dropdown -->
    <ul id="dropdownLang" class="dropdown-content lang-drop">
        <li><a href="/">English <img class="right" src="@/assets/img/gb-icon.png" alt=""></a></li>
        <li class="divider"></li>
        <li><a href="https://it.hofhrecipes.com/">Italiano <img class="right" src="@/assets/img/italy-icon.png" alt=""></a></li>
        <li class="divider"></li>
        <li><a href="https://ar.hofhrecipes.com/">العربية <img class="right" src="@/assets/img/ar-icon.png" alt=""></a></li>
        <li class="divider"></li>
        <li><a href="https://ca.hofhrecipes.com/en">English Canadian<img class="right" src="@/assets/img/ca-icon.png" alt=""></a></li>
        <li class="divider"></li>
        <li><a href="https://ca.hofhrecipes.com/fr">Français Canadien <img class="right" src="@/assets/img/ca-icon.png" alt=""></a></li>
    </ul>

    <ul id="slide-out" class="sidenav">
        <li>
            <div class="user-view">
                <img class="responsive-img" src="@/assets/img/signup-img.png" />
                <!-- <i class="medium material-icons">account_circle</i> -->
            </div>
        </li>
        <li v-if="user">
            <h2 class="center-align">{{user.email}}</h2>
        </li>
        <router-link to="/ShoppingList" class="sidenav-close">
            <li>
                <button class="btn blue">
                    <i class="material-icons right">shopping_basket</i>View shopping list
                </button>
            </li>
        </router-link>
        <!-- <li><a href="#">Update password</a></li> -->
        <li v-if="user">
            <a @click="logout">Logout</a>
        </li>
        <li v-if="user">
            <a @click="openDeleteModal" class="red-text sidenav-close">Delete account</a>
        </li>
    </ul>

    <ul id="slide-out-mobile" class="sidenav sidenav-mobile">
        <li>
            <div class="user-view mt-5">
                <!-- <img class="responsive-img" src="@/assets/img/signup-img.png" /> -->
                <!-- <h4>HoFH Recipes</h4> -->
                <!-- <i class="medium material-icons">account_circle</i> -->
            </div>
        </li>
        <li v-if="user" class="lightBlue">
            <p class="left-align valign-wrapper">
                <i class="material-icons left">account_circle</i>
                {{user.email}}
            </p>
        </li>
        <li v-if="user">
            <a class="left-align valign-wrapper" @click="closeMobileNavAndGoTo('ShoppingList')">
                Shopping List
                <i class="material-icons right">chevron_right</i>
            </a>
        </li>
        <h2 class="left-align label">Lifestyle Tips</h2>
        <li>
            <a class="left-align" @click="closeMobileNavAndGoTo('MaintainingWeight')">
                Maintaining weight
                <i class="material-icons right">chevron_right</i>
            </a>
        </li>
        <li>
            <a class="left-align" @click="closeMobileNavAndGoTo('EatingOnABudget')">
                Eating on a Budget
                <i class="material-icons right">chevron_right</i>
            </a>
        </li>
        <li>
            <a class="left-align" @click="closeMobileNavAndGoTo('SmartFoodSubstitutions')">
                Smart food substitutions
                <i class="material-icons right">chevron_right</i>
            </a>
        </li>
        <li>
            <a class="left-align" @click="closeMobileNavAndGoTo('CarbControl')">
                Carb control
                <i class="material-icons right">chevron_right</i>
            </a>
        </li>

        <h2 class="left-align label">Languages</h2>
        <li class="mobLang">
            <a href="/" class="mobLangBtn italy"><img src="@/assets/img/gb-icon.png" alt=""> English</a>
        </li>
        <li class="mobLang">
            <a href="https://it.hofhrecipes.com/" class="mobLangBtn english"><img src="@/assets/img/italy-icon.png" alt=""> Italiano</a>
        </li>
        <li class="mobLang">
            <a href="https://ar.hofhrecipes.com/" class="mobLangBtn english"><img src="@/assets/img/ar-icon.png" alt=""> العربية</a>
        </li>
        <li class="mobLang">
            <a href="https://ca.hofhrecipes.com/en" class="mobLangBtn english"><img src="@/assets/img/ca-icon.png" alt=""> English Canadian</a>
        </li>
        <li class="mobLang">
            <a href="https://ca.hofhrecipes.com/fr" class="mobLangBtn english"><img src="@/assets/img/ca-icon.png" alt=""> Français Canadien</a>
        </li>
        <!-- <li><a href="#">Update password</a></li> -->
        <li v-if="user">
            <a @click="logout">Logout</a>
        </li>
        <li v-if="user">
            <a @click="openDeleteModal" class="red-text">Delete account</a>
        </li>
    </ul>

    <div class="shelf-nav">
        <ul>
            <router-link to="/">
                <li>
                    <i class="material-icons">home</i>
                    <p>Home</p>
                </li>
            </router-link>
            <router-link v-if="!user" to="/profile">
                <li>
                    <i class="material-icons">account_box</i>
                    <p>Login</p>
                </li>
            </router-link>
            <router-link v-if="user" to="/favourites">
                <li>
                    <i class="material-icons">favorite</i>
                    <p>Favorites</p>
                </li>
            </router-link>
            <router-link to="/mealPlan">
                <li>
                    <i class="material-icons">assignment</i>
                    <p>Meal Planner</p>
                </li>
            </router-link>
            <router-link to="/profile">
                <li>
                    <i class="material-icons">insert_chart</i>
                    <p>Trackers</p>
                </li>
            </router-link>
            <a href="#" data-target="slide-out-mobile" class="sidenav-trigger">
                <li>
                    <i class="material-icons">menu</i>
                    <p>More</p>
                </li>
            </a>
        </ul>
    </div>

    <div id="deleteAccountModal" class="modal">
        <div class="modal-content">
            <h4>Are you sure you want to delete your account?</h4>
            <p>When you delete your account we delete all of your favorite recipes and previously tracked health data.</p>
        </div>
        <div class="modal-footer">
            <a href="#!" class="waves-effect waves-green btn-flat red-text" @click="deleteUser">Yes, delete my account</a>
            <a href="#!" class="waves-effect waves-green btn-flat green-text" @click="closeDeleteModal()">Cancel</a>
        </div>
    </div>
</div>
</template>

<script>
import db from "@/firebase/init";
import firebase from "firebase";
export default {
    name: "Navbar",
    data() {
        return {
            user: firebase.auth().currentUser,
            sidenavMobileInstance: null
        };
    },
    mounted() {
        this.user = firebase.auth().currentUser;

        /* eslint-disable */
        const sidenav = document.querySelector(".sidenav");
        M.Sidenav.init(sidenav);

        const sidenavMobile = document.querySelector(".sidenav-mobile");
        this.sidenavMobileInstance = M.Sidenav.init(sidenavMobile);

        const deleteAccountModal = document.querySelector("#deleteAccountModal");
        this.deleteAccountModal = M.Modal.init(deleteAccountModal);

        const dropdown = document.querySelectorAll(".dropdown-trigger");
        M.Dropdown.init(dropdown, {
            constrainWidth: false,
            hover: true,
            coverTrigger: false
        });

        var fixedAction = document.querySelectorAll('.fixed-action-btn');
        this.fixedAction = M.FloatingActionButton.init(fixedAction, {
            direction: 'left',
            hoverEnabled: false
        });
    },
    created() {
        // let user = firebase.auth().currentUser
        /* eslint-disable */
        // var instance = M.Sidenav.getInstance(elem);

        // document.addEventListener('DOMContentLoaded', function() {
        //   var elems = document.querySelectorAll('.sidenav');
        //   var instances = M.Sidenav.init(elems, options);
        // });

        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                this.user = user;
            } else {
                this.user = null;
            }
        });
    },
    methods: {
        logout() {
            firebase
                .auth()
                .signOut()
                .then(() => {
                    this.$router.push({
                        name: "login"
                    });
                });
        },

        closeDeleteModal() {
            this.deleteAccountModal.close();
        },

        closeMobileNavAndGoTo(page) {
            this.sidenavMobileInstance.close();
            this.$router.push({
                name: page
            });
        },
        openDeleteModal() {
            // this.sidenavMobileInstance.close();
            this.deleteAccountModal.open();
        },
        deleteUser() {
            // var userCollection = db
            //     .collection("users")
            //     .where("user_id", "==", this.user.uid);

            /* eslint-disable */
            // console.log(userCollection);

            var currUser = firebase.auth().currentUser;

            this.deleteAccountModal.close();

            this.$router.push({
                name: "login"
            });

            var userCollection = db.collection('users').where('user_id', '==', this.user.uid);
            userCollection.get().then(function (querySnapshot) {
                querySnapshot.forEach(function (doc) {
                    doc.ref.delete().then(function () {
                        currUser
                            .delete()
                            .then(function () {

                                var toastHTML = "<span>Account Deleted</span>";
                                M.toast({
                                    html: toastHTML
                                });
                            })
                            // .then(function() {
                            //   this.$router.push({ name: "login" });
                            // })
                            .catch(function (error) {
                                alert("Sorry, something went wrong please try again later. If you are still experiencing issues, contact info@hofhrecipes.com for more information");
                                console.log(error);
                            });
                    });
                });
            });

            // userCollection.get().then(function (querySnapshot) {
            //     querySnapshot.forEach(function (doc) {
            //         doc.ref.delete().then(() => {
            //             var currUser = firebase.auth().currentUser;

            //             currUser
            //                 .delete()
            //                 .then(function () {
            //                     this.deleteAccountModal.close();

            //                     var toastHTML = "<span>Account Deleted</span>";
            //                     M.toast({
            //                         html: toastHTML
            //                     });

            //                     this.$router.push({
            //                         name: "login"
            //                     });
            //                 })
            //                 // .then(function() {
            //                 //   this.$router.push({ name: "login" });
            //                 // })
            //                 .catch(function (error) {
            //                     alert("Sorry, something went wrong please try again later. If you are still experiencing issues, contact info@hofhrecipes.com for more information");
            //                     console.log(error);
            //                 });
            //         });
            //     });
            // });

            // userCollection.delete().then(function() {
            //     console.log("Document successfully deleted!");
            // }).catch(function(error) {
            //     console.error("Error removing document: ", error);
            // });
        },
        logInWarning(page) {
            if (this.user) {
                this.$router.push({
                    name: page
                });
            } else {
                var toastHTML = "<span>Login to access</span>";
                M.toast({
                    html: toastHTML
                });
                this.$router.push({
                    name: "login"
                });
            }
        }
    },
};
</script>

<style scoped>
nav {
    background-color: transparent;
    box-shadow: none;
    -webkit-box-shadow: none;
}

nav, nav .nav-wrapper i, nav a.sidenav-trigger, nav a.sidenav-trigger i{
    height: 34px;
    line-height: 34px;
}

nav .brand-logo {
    float: left;
    position: static;
}

nav .brand-logo i.material-icons {
    margin: 0 18px;
}

nav .nav-wrapper {
    padding-top: 21px;
}

nav ul a {
    font-size: 1em;
}

.sidenav{
    overflow: unset;
    /* padding-top: 70px; */
}

.sidenav-overlay{
    background-color: rgba(0,0,0,0.8);
}

.lightBlue{
    background-color: #f3fafb;
}

.mobLang{

}

.mobLangBtn{
    display: flex;
    align-items: center;
    /* width: 46%; */
    justify-content: flex-start;
    color:rgb(26, 114, 126);
}

.mobLangBtn img{
    max-width: 27px;
    margin-right: 10px;
}

/* .mobLangBtn.italy{
    border-right: 1px solid #eee;
} */

.dropdown-trigger {
    padding: 0 15px;
    transition: background-color 0.3s;
    float: left;
}

.dropdown-trigger:focus {
    outline-width: 0;
}

.dropdown-content li:hover,
.dropdown-content li.active {
    background-color: #fcb812;
    color: #fff;
}

.dropdown-content a:hover {
    background-color: transparent;
}

.dropdown-content li>a,
.dropdown-content li>span {
    color: #1a727d;
}

.light-blue.darken-1 {
    background: rgb(0, 161, 201);
    background: -moz-linear-gradient(319deg,
            rgba(0, 161, 201, 1) 0%,
            rgba(2, 178, 209, 1) 100%);
    background: -webkit-linear-gradient(319deg,
            rgba(0, 161, 201, 1) 0%,
            rgba(2, 178, 209, 1) 100%);
    background: linear-gradient(319deg,
            rgba(0, 161, 201, 1) 0%,
            rgba(2, 178, 209, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00a1c9", endColorstr="#02b2d1", GradientType=1);
}

.sidenav {
    z-index: 1000;
}

.sidenav-trigger {
    display: block;
}

.sidenav .user-view {
    position: relative;
    padding: 0;
    margin-bottom: 8px;
    height: auto;
}

.sidenav .user-view img {
    width: 60%;
    padding-top: 20px;
}

.sidenav-mobile .user-view img {
    width: 40%;
}

.sidenav h2 {
    font-size: 1rem;
    margin: 1em;
}

.sidenav-mobile h4 {
    color: rgb(26, 114, 126);
    text-align: left;
    margin: 0;
    padding: 0 32px;
}

.sidenav-mobile p {
    color: rgb(26, 114, 126);
    margin: 0;
    padding: 0 32px;
}

.sidenav-mobile p i {
    margin-right: 10px;
}

.sidenav-mobile .label {
    padding: 0 32px;
    margin: 2em 0 0 0;
}

.sidenav .btn.blue {
    background: linear-gradient(319deg,
            rgba(0, 161, 201, 1) 0%,
            rgba(2, 178, 209, 1) 100%) !important;
    box-shadow: none;
}

.sidenav li>a {
    color: rgb(26, 114, 126);
    font-size: 1em;
    cursor: pointer;
}

.sidenav-mobile li {
    border-bottom: 1px solid #eee;
}

.sidenav-mobile li>a>i,
.sidenav-mobile li>a>[class^="mdi-"],
.sidenav-mobile li>a li>a>[class*="mdi-"],
.sidenav-mobile li>a>i.material-icons .sidenav .user-view img {
    margin: 0 !important;
}

.shelf-nav {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background: #02bad3;
    display: none;
}

.shelf-nav p {
    margin: 0;
    padding: 0;
    font-size: 10px;
    margin-top: -2px;
    color: #fff;
}

.shelf-nav ul {
    margin: 0;
}

.shelf-nav a {
    display: inline-block;
    margin: 0;
    height: 10%;
    width: 20%;
    padding: 14px 0;
}

.shelf-nav ul li i {
    font-size: 30px;
    color: #fff;
}

.shelf-nav .mob-log {
    color: #fff;
}

#deleteAccountModal h4 {
    color: #f26a53;
}

.deep-orange.accent-2 {
    background-color: #f26a53 !important;
}

.dropdownLangBtn{
    background-color: #1a727d;
}

#dropdownLang li a{
    display: flex;
    align-items: center;
}

#dropdownLang li img{
    max-width: 30px;
    margin-left: 1em;
}

@media screen and (max-device-width: 992px) {
    nav .brand-logo {
        transform: none;
    }

    nav .sidenav-trigger {
        margin: 0;
    }
}

@media screen and (max-device-width: 320px) {
    .sidenav-mobile .user-view img[data-v-41458b80] {
        width: 18%;
    }
}
</style>
