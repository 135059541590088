<template>
  <div id="app">
    <Navbar v-if="$route.meta.isEN" />
      <router-view></router-view>
    <Footer v-if="$route.meta.isEN" />
  </div>
</template>

<script>
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import 'materialize-css/dist/css/materialize.min.css'
import 'materialize-css/dist/js/materialize.min.js'
import "@/assets/css/global.css";

export default {
  name: "App",
  components: {
    Navbar,
    Footer
  },
  created() {
  },
  mounted() {
  }
};
</script>

<style scoped>
/* @import './assets/js/materialize.min.js'; */
/* @import './assets/css/materialize.min.css'; */

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
